exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-activity-management-jsx": () => import("./../../../src/pages/activity-management.jsx" /* webpackChunkName: "component---src-pages-activity-management-jsx" */),
  "component---src-pages-agency-jsx": () => import("./../../../src/pages/agency.jsx" /* webpackChunkName: "component---src-pages-agency-jsx" */),
  "component---src-pages-bancassurance-jsx": () => import("./../../../src/pages/bancassurance.jsx" /* webpackChunkName: "component---src-pages-bancassurance-jsx" */),
  "component---src-pages-banking-crm-jsx": () => import("./../../../src/pages/banking-crm.jsx" /* webpackChunkName: "component---src-pages-banking-crm-jsx" */),
  "component---src-pages-banking-sales-solutions-jsx": () => import("./../../../src/pages/banking-sales-solutions.jsx" /* webpackChunkName: "component---src-pages-banking-sales-solutions-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-case-study-aia-indonesia-jsx": () => import("./../../../src/pages/case-study/aia-indonesia.jsx" /* webpackChunkName: "component---src-pages-case-study-aia-indonesia-jsx" */),
  "component---src-pages-case-study-bharti-axa-jsx": () => import("./../../../src/pages/case-study/bharti-axa.jsx" /* webpackChunkName: "component---src-pages-case-study-bharti-axa-jsx" */),
  "component---src-pages-case-study-hdfc-bank-jsx": () => import("./../../../src/pages/case-study/hdfc-bank.jsx" /* webpackChunkName: "component---src-pages-case-study-hdfc-bank-jsx" */),
  "component---src-pages-case-study-sbi-life-jsx": () => import("./../../../src/pages/case-study/sbi-life.jsx" /* webpackChunkName: "component---src-pages-case-study-sbi-life-jsx" */),
  "component---src-pages-collections-jsx": () => import("./../../../src/pages/collections.jsx" /* webpackChunkName: "component---src-pages-collections-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-crm-plus-jsx": () => import("./../../../src/pages/crm-plus.jsx" /* webpackChunkName: "component---src-pages-crm-plus-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insurance-crm-jsx": () => import("./../../../src/pages/insurance-crm.jsx" /* webpackChunkName: "component---src-pages-insurance-crm-jsx" */),
  "component---src-pages-insurance-sales-solutions-jsx": () => import("./../../../src/pages/insurance-sales-solutions.jsx" /* webpackChunkName: "component---src-pages-insurance-sales-solutions-jsx" */),
  "component---src-pages-lead-management-jsx": () => import("./../../../src/pages/lead-management.jsx" /* webpackChunkName: "component---src-pages-lead-management-jsx" */),
  "component---src-pages-lending-jsx": () => import("./../../../src/pages/lending.jsx" /* webpackChunkName: "component---src-pages-lending-jsx" */),
  "component---src-pages-partner-management-jsx": () => import("./../../../src/pages/partner-management.jsx" /* webpackChunkName: "component---src-pages-partner-management-jsx" */),
  "component---src-pages-partner-with-vymo-jsx": () => import("./../../../src/pages/partner-with-vymo.jsx" /* webpackChunkName: "component---src-pages-partner-with-vymo-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-product-overview-jsx": () => import("./../../../src/pages/product-overview.jsx" /* webpackChunkName: "component---src-pages-product-overview-jsx" */),
  "component---src-pages-request-a-demo-jsx": () => import("./../../../src/pages/request-a-demo.jsx" /* webpackChunkName: "component---src-pages-request-a-demo-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../../../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */),
  "component---src-pages-unified-banking-jsx": () => import("./../../../src/pages/unified-banking.jsx" /* webpackChunkName: "component---src-pages-unified-banking-jsx" */),
  "component---src-pages-use-cases-improve-wholesaler-productivity-jsx": () => import("./../../../src/pages/use-cases/improve-wholesaler-productivity.jsx" /* webpackChunkName: "component---src-pages-use-cases-improve-wholesaler-productivity-jsx" */),
  "component---src-pages-use-cases-index-jsx": () => import("./../../../src/pages/use-cases/index.jsx" /* webpackChunkName: "component---src-pages-use-cases-index-jsx" */),
  "component---src-pages-vymo-coach-jsx": () => import("./../../../src/pages/vymo-coach.jsx" /* webpackChunkName: "component---src-pages-vymo-coach-jsx" */),
  "component---src-pages-vymo-faq-jsx": () => import("./../../../src/pages/vymo-faq.jsx" /* webpackChunkName: "component---src-pages-vymo-faq-jsx" */),
  "component---src-pages-vymo-security-jsx": () => import("./../../../src/pages/vymo-security.jsx" /* webpackChunkName: "component---src-pages-vymo-security-jsx" */),
  "component---src-pages-why-vymo-jsx": () => import("./../../../src/pages/why-vymo.jsx" /* webpackChunkName: "component---src-pages-why-vymo-jsx" */)
}

